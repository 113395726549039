import { Theme } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { StartPageRole } from '../../constants/RegisterOptions'
import useI18n from '../../lib/hooks/useI18n'
import { getTileData } from './RoleTilesData'
import Tile from './Tile'

interface Props {
  onChange: (role: StartPageRole) => void
}

const useStyles = makeStyles((theme: Theme) => ({
  iAmText: {
    fontSize: '22px',
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4),
    },
    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(2),
    },
  },
  container: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      gap: theme.spacing(6),
      marginLeft: theme.spacing(8),
      marginRight: theme.spacing(8),
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(4),
      flexDirection: 'column',
      gap: theme.spacing(2),
    },
  },
}))

const RoleSelect = ({ onChange }: Props) => {
  const { i18n } = useI18n()
  const classes = useStyles()
  const [selectedTile, setSelectedTile] = useState<number>(-1)
  return (
    <>
      <div className={classes.iAmText}>{i18n.iAm}</div>
      <div className={classes.container}>
        {getTileData(i18n).map((data, idx) => (
          <Tile
            key={idx}
            header={data.header}
            content={data.content}
            imageSrc={data.image}
            isSelected={selectedTile === -1 || selectedTile === idx}
            onSelect={() => {
              setSelectedTile(idx)
              onChange(data.role)
            }}
          ></Tile>
        ))}
      </div>
    </>
  )
}

export default RoleSelect
