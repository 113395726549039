import HttpStatus from '../../constants/HttpStatus'
import { AsyncReturnType, ValueOf } from '../../types'
import { useLoaderContext } from '../contexts/FullPageLoaderContext'
import FetchError from '../errors/FetchError'

const useApi = () => {
  const { setIsLoading } = useLoaderContext()

  const fetchWithErrHandle = async <T, U>({
    fn,
    onSuccess,
    ...handlers
  }: {
    fn: (...args: T[]) => Promise<U>
    onSuccess: (res: U) => any
    default?: (e: any) => void
  } & Partial<Record<ValueOf<typeof HttpStatus>, (e: any) => void>>) => {
    try {
      setIsLoading(true)
      const res = await fn()
      setIsLoading(false)
      return onSuccess(res)
    } catch (e: any) {
      setIsLoading(false)
      if (!handlers.default) {
        throw e
      }
      if (!(e instanceof FetchError)) {
        return handlers.default(e)
      }
      if (handlers[e.status]) {
        return handlers[e.status]!(e)
      }
      return handlers.default(e)
    }
  }

  const fetchWithProgress =
    <T extends (...args: any[]) => any>(fn: T) =>
    async (...args: Parameters<T>): Promise<AsyncReturnType<T>> => {
      try {
        setIsLoading(true)
        const res = await fn(...args)
        setIsLoading(false)
        return res
      } catch (e) {
        setIsLoading(false)
        throw e
      }
    }

  return {
    fetchWithErrHandle,
    fetchWithProgress,
  }
}

export default useApi
