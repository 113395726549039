import { Box, TextField, Button, Theme, Grid } from '@mui/material'
import React, { Fragment } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import useI18n from '../../lib/hooks/useI18n'
import SendIcon from '@mui/icons-material/Send'
import { useGlobalStyles } from '../../constants/Theme'
import { StartPageRole } from '../../constants/RegisterOptions'
import useErrorMessage from './Start/useErrorMessage'
import { Language } from '../../../config/Languages'
import { isValidEmail } from '../../lib/validation/Email'
import { EmailStatus } from '../../api/Register'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles((theme: Theme) => ({
  emailContainer: {
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '3em 8em',
    [theme.breakpoints.between('sm', 'lg')]: {
      padding: '3em 3em',
    },
    [theme.breakpoints.between(0, 'md')]: {
      padding: '2em 2em',
    },
  },
  emailRow: {
    display: 'flex',
    alignItems: 'center',
  },
  emailInput: {
    widht: '10em',
  },
  whiteText: {
    color: 'white',
  },
  href: {
    color: theme.palette.primary.main,
  },
}))

interface Props {
  role: StartPageRole
  emailStatus?: EmailStatus
}

const getThirdPartyUrl = (locale: Language) => {
  return locale === 'de'
    ? 'https://www.dentsplysirona.com/de-de/customer-support/kontaktieren-sie-uns.html'
    : 'https://www.dentsplysirona.com/en/service-contact.html'
}

const EmailContainer = ({ role, emailStatus }: Props) => {
  const classes = useStyles()
  const { i18n, locale } = useI18n()
  const { control, formState, getValues } = useFormContext()
  const globalClasses = useGlobalStyles()
  const { displayText, requiredAction } = useErrorMessage({
    email: getValues()['email'],
    role,
    emailStatus,
  })

  return (
    <Box className={classes.emailContainer}>
      {role !== 'thirdParty' && (
        <Grid
          container
          spacing={1}
          className={classes.emailRow}
          alignItems="flex-end"
        >
          <Grid item md={5} xs={12} className={globalClasses.bold}>
            {i18n.yourEmailAddress}

            <Grid container spacing={1} alignItems="flex-end">
              <Grid item md={7} xs={12}>
                <Controller
                  render={(props) => (
                    <TextField
                      {...props}
                      className={classes.emailInput}
                      fullWidth
                      label={i18n.emailFieldLabel}
                      error={Boolean(formState.errors['email'])}
                      helperText={
                        Boolean(formState.errors['email']) &&
                        i18n.provideValidEmail
                      }
                    />
                  )}
                  name="email"
                  control={control}
                  rules={{
                    required: true,
                    validate: { isValidEmail },
                  }}
                  defaultValue=""
                />
              </Grid>
              <Grid item md={5} xs={12}>
                <Button
                  className={classes.whiteText}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color={emailStatus ? 'primary' : 'secondary'}
                  endIcon={<SendIcon />}
                >
                  {i18n.check}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item md={1} />
          <Grid item md={6} xs={12} className={globalClasses.bold}>
            {emailStatus && displayText}
            {emailStatus && requiredAction}
          </Grid>
        </Grid>
      )}
      {role === 'thirdParty' && (
        <Fragment>
          <div>{i18n.asThirdPartyPleaseCall}</div>
          <br />
          <div>
            {i18n.accessToTheDentsply} {i18n.pleaseContact}
          </div>
          <br />
          <div>
            {i18n.findPhoneNumbers}
            <a
              className={classes.href}
              href={getThirdPartyUrl(locale)}
              target="_blank"
              rel="noreferrer"
            >
              {getThirdPartyUrl(locale)}
            </a>
          </div>
        </Fragment>
      )}
    </Box>
  )
}

export default EmailContainer
