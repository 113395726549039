import { I18n } from '../../lib/hooks/useI18n'
import { env } from 'src/env.mjs'

export const getTileData = (i18n: I18n) =>
  [
    {
      header: '',
      content: i18n.authorizedTechnicianSales,
      image: `${env.NEXT_PUBLIC_BASE_PATH}/images/start/TechnicianSales.png`,
      role: 'technicianSales',
    },
    {
      header: i18n.endCustomer,
      content: i18n.egDentist,
      image: `${env.NEXT_PUBLIC_BASE_PATH}/images/start/EndCustomer.png`,
      role: 'endCustomer',
    },
    {
      header: i18n.thirdParty,
      content: i18n.egITService,
      image: `${env.NEXT_PUBLIC_BASE_PATH}/images/start/ThirdParty.png`,
      role: 'thirdParty',
    },
  ] as const
