import { makeStyles } from '@mui/styles'
import { Theme } from '@mui/material/styles'

interface Props {
  wrapperText: string
  hrefLabel: string
  url: string
  newTab?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  url: {
    color: theme.palette.primary.main,
  },
}))

export { useStyles }

const HrefWithText = ({
  url,
  hrefLabel,
  wrapperText,
  newTab = false,
}: Props) => {
  const classes = useStyles()
  const [textPreReplace = '', textPostReplace = ''] = wrapperText.split('%s')

  return (
    <span>
      {textPreReplace}{' '}
      <a
        className={classes.url}
        href={url}
        target={newTab ? '_blank' : ''}
        rel="noreferrer"
      >
        {hrefLabel}
      </a>
      {textPostReplace}
    </span>
  )
}

export default HrefWithText
