/* eslint-disable react/display-name */
import { Box, Button, Theme } from '@mui/material'
import React, { useState } from 'react'
import { EmailStatus } from '../../../api/Register'
import {
  SIMO_PROD_URL,
  SIMO_EMAIL,
  EKP_PROD_URL,
} from '../../../constants/Constants'
import { StartPageRole } from '../../../constants/RegisterOptions'
import useI18n from '../../../lib/hooks/useI18n'
import HrefWithText from '../../HrefWithText'
import { useRouter } from 'next/router'
import { makeStyles } from '@mui/styles'
import { Info, Send } from '@mui/icons-material'

interface Props {
  role?: StartPageRole
  email: string
  emailStatus?: EmailStatus
}

export const getDentsplyNavData = (email: string): string => {
  const data = {
    data: { login: email, email },
    documentId: '',
    preventTrigger: false,
    randomString: 'zu3u0',
  }
  return btoa(JSON.stringify(data))
}

const useStyles = makeStyles((theme: Theme) => ({
  redirectText: {
    fontStyle: 'italic',
    fontWeight: 'normal',
  },
  text: {
    fontSize: 12,
    color: theme.palette.primary.main,
  },
  whiteText: {
    color: 'white',
  },
  textMargin: {},
}))

const Text = ({
  mainText,
  helpText,
}: {
  mainText: string
  helpText?: string
}) => {
  const classes = useStyles()
  return (
    <Box
      sx={{
        my: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Info fontSize="small" color="primary" />
        <span className={classes.text}>{mainText}</span>
      </Box>

      {helpText && <span className={classes.text}>{helpText}</span>}
    </Box>
  )
}

const RegisterButton = ({
  text,
  onClick,
}: {
  text: string
  onClick: () => void
}) => {
  const classes = useStyles()
  return (
    <Button
      className={classes.whiteText}
      size="large"
      variant="contained"
      color="secondary"
      fullWidth
      endIcon={<Send />}
      onClick={onClick}
    >
      {text}
    </Button>
  )
}

export const useErrorMessage = ({ role, email, emailStatus }: Props) => {
  const { i18n } = useI18n()
  const classes = useStyles()
  const router = useRouter()
  const [hasRedirectedToSimo, setHasRedirectedToSimo] = useState(false)
  const [hasRedirectedToEkp, setHasRedirectedToEkp] = useState(false)

  if (role === 'thirdParty' || !emailStatus) {
    return {
      displayText: '',
      requiredAction: '',
    }
  }

  const caseMapping = {
    technicianSales: {
      EKP: {
        text: () => <Text mainText={i18n.alreadyExistingInEKPButTechnician} />,
        requiredAction: () => (
          <HrefWithText
            url={`mailto:${SIMO_EMAIL}`}
            hrefLabel={SIMO_EMAIL}
            wrapperText={i18n.wantToTransferToClassic}
          ></HrefWithText>
        ),
      },
      'SiMo Classic': {
        text: () => <Text mainText={i18n.alreadyRegisteredInClassic} />,
        requiredAction: () => {
          if (!hasRedirectedToSimo) {
            setTimeout(() => {
              window.open(SIMO_PROD_URL, '_blank')
            }, 3000)
            setHasRedirectedToSimo(true)
          }
          return (
            <div className={classes.redirectText}>
              {i18n.redirectedToTechnicianPortal}
            </div>
          )
        },
      },
      default: {
        text: () => (
          <Text
            mainText={i18n.notRegisteredInClassic}
            helpText={
              !emailStatus.whitelist ? i18n.notOfficialDealerDomain : undefined
            }
          />
        ),
        requiredAction: () => (
          <RegisterButton
            text={i18n.registerHere}
            onClick={() => router.push(`/register?email=${email}`)}
          />
        ),
      },
    },
    endCustomer: {
      EKP: {
        text: () => <Text mainText={i18n.alreadyRegisteredEKPStart} />,
        requiredAction: () => {
          const url = `${EKP_PROD_URL}#cr/generic/PreloginLogin/${getDentsplyNavData(
            email
          )}/`
          if (!hasRedirectedToEkp) {
            setTimeout(() => {
              window.open(url, '_blank')
            }, 3000)
            setHasRedirectedToEkp(true)
          }
          return (
            <div className={classes.redirectText}>
              {i18n.redirectedToCustomerPortal}
            </div>
          )
        },
      },
      'SiMo Classic': {
        text: () => <Text mainText={i18n.alreadyExistingInTechnicianButEKP} />,
        requiredAction: () => (
          <HrefWithText
            url={`mailto:${SIMO_EMAIL}`}
            hrefLabel={SIMO_EMAIL}
            wrapperText={i18n.wantToTransferToEKP}
          ></HrefWithText>
        ),
      },
      default: {
        text: () => <Text mainText={i18n.notRegisteredInEKP} />,
        requiredAction: () => (
          <RegisterButton
            text={i18n.registerHere}
            onClick={() =>
              window.open(
                `${EKP_PROD_URL}#cr/generic/PreloginRegisterOrganization/${getDentsplyNavData(
                  email
                )}/`,
                '_blank'
              )
            }
          />
        ),
      },
    },
  } as const

  const { text, requiredAction } =
    caseMapping[role!][emailStatus?.application || 'default']

  return {
    displayText: text(),
    requiredAction: requiredAction(),
  }
}

export default useErrorMessage
