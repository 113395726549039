import React from 'react'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  logo: {
    width: '182px',
  },
  headingSeparator: {
    background: 'linear-gradient(90deg, #0077c8, #f8a700)',
    margin: '1em 0 1em 0',
    height: '0.5em',
    width: '100%',
  },
}))

const HeaderGradient = () => {
  const classes = useStyles()
  return <div className={classes.headingSeparator} />
}

export default HeaderGradient
