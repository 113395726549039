import { Theme, Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { EmailStatus, getEmailStatus } from '../../../api/Register'
import { StartPageRole } from '../../../constants/RegisterOptions'
import useApi from '../../../lib/hooks/useApi'
import useI18n from '../../../lib/hooks/useI18n'
import EmailContainer from '../EmailContainer'
import RoleSelect from '../RoleSelect'
import StartHeader from './StartHeader'

const useStyles = makeStyles((theme: Theme) => ({
  emailContainer: {
    backgroundColor: theme.palette.background.default,
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    height: theme.spacing(20),
    padding: '1em 10em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  welcomeTextContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    height: theme.spacing(15),
    backgroundColor: '#E1EFF9',
    padding: '0 5em',
    [theme.breakpoints.down('xs')]: {
      padding: '0 3em',
    },
  },
  welcomeText: {
    color: theme.palette.primary.main,
    fontSize: '20pt',
  },
}))

const Start = () => {
  const { i18n } = useI18n()
  const classes = useStyles()
  const [role, setRole] = useState<StartPageRole | ''>('')
  const formData = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  })
  const { fetchWithProgress } = useApi()
  const [emailStatus, setEmailStatus] = useState<EmailStatus>()

  const onSubmit = (data: any) => {
    fetchWithProgress(getEmailStatus)(data.email).then(setEmailStatus)
  }
  return (
    <FormProvider {...formData}>
      <StartHeader />

      <form onSubmit={formData.handleSubmit(onSubmit)} noValidate>
        <Box className={classes.welcomeTextContainer}>
          <span className={classes.welcomeText}>
            {i18n.welcomeDentsplyProductService}
          </span>
        </Box>
        <RoleSelect
          onChange={(role) => {
            setEmailStatus(undefined)
            setRole(role)
          }}
        />
        {role && <EmailContainer role={role} emailStatus={emailStatus} />}
      </form>
    </FormProvider>
  )
}

export default Start
