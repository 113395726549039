import { Box, Theme } from '@mui/material'
import { useRouter } from 'next/router'
import React, { Fragment } from 'react'
import { EKP_PROD_URL, SIMO_PROD_URL } from '../../../constants/Constants'
import useI18n from '../../../lib/hooks/useI18n'
import HeaderGradient from '../../Layout/HeaderGradient'
import { makeStyles } from '@mui/styles'
import { HelpOutlineOutlined } from '@mui/icons-material'
import { env } from 'src/env.mjs'

const useStyles = makeStyles((theme: Theme) => ({
  logo: {
    width: '182px',
  },
  href: {
    color: theme.palette.primary.main,
    margin: theme.spacing(1),
  },
  icon: {
    cursor: 'pointer',
  },
}))
const StartHeader = () => {
  const classes = useStyles()
  const { i18n } = useI18n()
  const router = useRouter()
  return (
    <Fragment>
      <HeaderGradient />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <img
          alt="logo"
          className={classes.logo}
          src={`${env.NEXT_PUBLIC_BASE_PATH}/images/Logo-Header-Dentsply-260x98px.png`}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <a
            className={classes.href}
            href={EKP_PROD_URL}
            target="_blank"
            rel="noreferrer"
          >
            {i18n.loginComplaintPortal}
          </a>
          <span className={classes.href}>{' | '}</span>
          <a
            className={classes.href}
            href={SIMO_PROD_URL}
            target="_blank"
            rel="noreferrer"
          >
            {i18n.loginTechnicianPortal}
          </a>
          <HelpOutlineOutlined
            color="primary"
            fontSize="large"
            className={classes.icon}
            onClick={() => router.push('/help')}
          />
        </Box>
      </Box>
    </Fragment>
  )
}

export default StartHeader
