import { Box, Theme, Typography, useMediaQuery, useTheme } from '@mui/material'
import React from 'react'
import clsx from 'clsx'
import { useGlobalStyles } from '../../constants/Theme'
import { makeStyles } from '@mui/styles'

interface Props {
  isSelected: boolean
  onSelect: () => void
  header?: string
  content: string
  imageSrc: string
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '20em',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
  },
  mobileContainer: {
    display: 'flex',
    padding: '1.5em',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer',
    gap: theme.spacing(3),
  },
  image: {
    width: '100%',
  },
  mobileImage: {
    width: '4em',
    height: '4em',
    objectFit: 'cover',
    borderRadius: '50%',
  },
  isNotSelected: {
    opacity: '0.5',
  },
  texts: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
}))

const Tile = ({ imageSrc, content, header, onSelect, isSelected }: Props) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const theme = useTheme()
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'))

  if (isMobileView && !isSelected) {
    return <div></div>
  }
  return (
    <div
      onClick={onSelect}
      className={clsx(
        isMobileView ? classes.mobileContainer : classes.container,
        !isSelected && classes.isNotSelected
      )}
    >
      <img
        className={isMobileView ? classes.mobileImage : classes.image}
        src={imageSrc}
        alt="startTile"
      />

      <Box className={classes.texts}>
        <Typography className={globalClasses.bold}>{header}</Typography>
        {content.split('<br>').map((c, idx) => (
          <Typography key={idx}>{c}</Typography>
        ))}
      </Box>
    </div>
  )
}

export default Tile
